const ELEMENT_PRODUCT_IMAGE_WRAPPER = '';
const MEN_CATEGORY = 'men';
const MEN_CATEGORY_PATH = '/men/';

const CAROUSEL_DEFAULT_SETTINGS = {
  dots: true,
  arrows: true,
  autoplay: false,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  accessibility: true,
};

export {
  ELEMENT_PRODUCT_IMAGE_WRAPPER,
  CAROUSEL_DEFAULT_SETTINGS,
  MEN_CATEGORY,
  MEN_CATEGORY_PATH,
};
